export type AcceptedImageMimeType = "jpeg" | "png" | "webp";

export function convert(imgFileData: File, targetFormat: AcceptedImageMimeType) {
    try {
        let reader = new FileReader();
        return new Promise(resolve => {
            reader.onload = function (readerEvent) {
                let image = new Image();

                image.onload = function (imageEvent) {
                    let canvas = document.createElement('canvas');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    canvas.getContext('2d')!.drawImage(image, 0, 0, image.width, image.height);
                    let mimeType = 'image/' + targetFormat;
                    let dataUrl = canvas.toDataURL(mimeType);

                    let convertedImage = {
                        name: imgFileData.name.substring(0, imgFileData.name.lastIndexOf(".")),
                        format: targetFormat,
                        data: dataUrl
                    }
                    resolve(convertedImage);
                }
                image.src = readerEvent.target!.result as string;
            }
            reader.readAsDataURL(imgFileData);
        });
    } catch (e) {
        console.error("Error occurred while converting : ", e);
    }
}
