"use client"
import React, {Suspense, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCirclePlus, faFileDownload, faSpinner, faTrash, faWrench} from "@fortawesome/free-solid-svg-icons";
import {AcceptedImageMimeType, convert} from "@/app/convert";

export default function Home() {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [selectedFormat, setSelectedFormat] = useState<AcceptedImageMimeType>("jpeg");
    const [convertedImage, setConvertImages] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <main className="flex flex-col items-center justify-evenly m-4 lg:p-24 text-sm font-mono animate-in"
              onDragOver={(e) => {
                  e.preventDefault()
              }}
              onDrop={(e) => {
                  e.preventDefault();
                  if (e.dataTransfer.files.length < 0 || e.dataTransfer.files[0]?.type.indexOf("image") === -1) return;
                  setSelectedFile(e.dataTransfer.files[0]);
              }}>
            <div
                className="w-full lg:w-1/2 md:w-2/3 h-1/2 m-4 flex flex-col items-center justify-evenly p-12 lg:p-20 border border-dashed rounded-xl hover:bg-neutral-900 transition-colors cursor-pointer"
                onClick={() =>
                    document.getElementById("file-input")?.click()
                }>
                {selectedFile ? <i>{selectedFile.name}</i> :
                    <FontAwesomeIcon icon={faFileCirclePlus} style={{fontSize: '4rem'}} className="p-20"/>}
                <input type={"file"}
                       id={"file-input"}
                       hidden={true}
                       accept={"image/*"}
                       onChange={(e) => {
                           if (e.target.files) {
                               setSelectedFile(e.target.files[0]);
                           }
                       }
                       }
                />
            </div>
            <div className="w-full lg:w-1/2 md:w-2/3 my-4 flex items-center gap-2 justify-evenly flex-wrap">
                <button
                    className={'p-4 border flex-grow border-solid rounded-lg enabled:hover:bg-neutral-900 disabled:cursor-not-allowed transition-colors disabled:opacity-50'}
                    disabled={!selectedFile} onClick={async () => {
                    setLoading(true);
                    setConvertImages(await convert(selectedFile!, selectedFormat));
                    setLoading(false);
                }}>Convert <FontAwesomeIcon icon={faWrench}
                                            style={{fontSize: '1rem'}}/>
                </button>
                <select
                    aria-label={"Select image format"}
                    className={'p-4 border border-solid rounded-lg hover:bg-neutral-900 transition-colors'}
                    value={selectedFormat}
                    onChange={(e) => setSelectedFormat(e.target.value as AcceptedImageMimeType)}>
                    <option value={"jpg"}>JPG</option>
                    <option value={"png"}>PNG</option>
                    <option value={"webp"}>WEBP</option>
                </select>
            </div>
            <div className="w-full lg:w-1/2 md:w-2/3 flex items-center justify-evenly">
                {
                    loading ? <FontAwesomeIcon icon={faSpinner}
                                               style={{fontSize: '1rem'}}
                                               spin={true}/> :
                        convertedImage && (
                            <div className={'w-full flex flex-col justify-evenly animate-in'}>
                                <Suspense fallback={<FontAwesomeIcon icon={faSpinner} spin={true}/>}>
                                    <img src={convertedImage.data} alt={'Could not convert: ' + convertedImage.name}></img>
                                </Suspense>
                                <a id="file-download" hidden={true} href={convertedImage.data}
                                   download={`${convertedImage.name}.${convertedImage.format}`}>
                                </a>
                                <button
                                    className={'p-4 my-4 mt-8 border border-solid rounded-lg hover:bg-neutral-900 transition-colors'}
                                    onClick={() => {
                                        document.getElementById("file-download")?.click()
                                    }}>
                                    Download <FontAwesomeIcon icon={faFileDownload} style={{fontSize: '1rem'}}/></button>
                                <button
                                    className={'p-4 mb-4 border border-solid rounded-lg hover:bg-neutral-900 transition-colors'}
                                    onClick={() => setConvertImages(undefined)}>Clear <FontAwesomeIcon icon={faTrash}
                                                                                                       style={{fontSize: '1rem'}}/>
                                </button>
                            </div>
                        )}
            </div>
        </main>
    );
}
